.reactEasyCrop_Container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    user-select: none;
    touch-action: none;
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .reactEasyCrop_Image,
  .reactEasyCrop_Video {
    will-change: transform; /* this improves performances and prevent painting issues on iOS Chrome */
  }
  
  .reactEasyCrop_Contain {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .reactEasyCrop_Cover_Horizontal {
    width: 100%;
    height: auto;
  }
  .reactEasyCrop_Cover_Vertical {
    width: auto;
    height: 100%;
  }
  
  .reactEasyCrop_CropArea {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0 0 0 9999em;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
  
  .reactEasyCrop_CropAreaRound {
    border-radius: 50%;
  }
  
  .reactEasyCrop_CropAreaGrid::before {
    content: ' ';
    box-sizing: border-box;
    position: absolute;
    border: 1px solid rgba(255, 0, 0, 0.863);
    top: 0;
    bottom: 0;
    left: 33.33%;
    right: 33.33%;
    border-top: 0;
    border-bottom: 0;
  }
  
  .Slider {
    padding: 22px 0px;
  }
  .reactEasyCrop_CropAreaGrid::after {
    content: ' ';
    box-sizing: border-box;
    position: absolute;
    border: 1px solid rgba(255, 0, 0, 0.863);
    top: 33.33%;
    bottom: 33.33%;
    left: 0;
    right: 0;
    border-left: 0;
    border-right: 0;
  }
  